import { Col, Container, PageBanner, Pagination, Row, SearchInput, SocialButton, Stack, Text, Thumbnail, usePagination, useSearch } from '@zigurous/forge-react'; // prettier-ignore
import { Link as GatsbyLink, graphql } from 'gatsby';
import React, { useCallback } from 'react';
import { Page } from '../components';
import { trackEvent } from '../firebase/analytics';
import type { AssetData } from '../types';

const metadata = {
  url: 'https://zigurous.com/assets',
  title: 'Zigurous • Assets',
  description:
    'Zigurous creates flexible, customizable Unity assets to help new and experienced developers make games easier.',
};

function searchQuery(item: AssetData, query: string) {
  const search = item.searchQuery || item.title.toLowerCase().replace(' ', '');
  return search.includes(query);
}

interface AssetsProps {
  data: {
    json: {
      assets: AssetData[];
    };
  };
  location: Location;
}

export default function Assets({ data, location }: AssetsProps) {
  const { assets } = data.json;
  const [pagination, setPage] = usePagination(assets, 6, 'assets-page');
  const [search] = useSearch(assets, searchQuery, 'assets-search');
  const onSearch = useCallback(
    (query: string) => {
      search.onSearch(query);
      if (query) {
        trackEvent('search', {
          content_type: 'input',
          event_category: 'engagement',
          event_label: 'Search for assets',
          search_term: query,
        });
      }
    },
    [search.onSearch],
  );
  return (
    <Page className="assets" location={location} metadata={metadata}>
      <PageBanner className="bg-surface-1">
        <Container fluid>
          <Row>
            <Col xl={10}>
              <Text
                as="div"
                className="ml-xxxs"
                marginBottom="xs"
                type="subtitle"
              >
                Free and Premium
              </Text>
              <Text as="h1" marginBottom="lg" nowrap type="title-lg">
                Game Assets
              </Text>
              <Text className="text-pretty" marginBottom="lg" type="body-lg">
                We create flexible, customizable Unity assets to help new and
                experienced developers make games easier. These assets comprise
                a larger suite of tools that allow developers to create many
                kinds of games. Our assets can be used in both commercial and
                non-commercial projects.
              </Text>
              <Stack align="center" className="my-sm" spacing="md" wrap>
                <SocialButton
                  link="unity"
                  onClick={() => {
                    trackEvent('social_button', {
                      content_type: 'button',
                      event_category: 'engagement',
                      event_label: 'Asset Store',
                    });
                  }}
                  shape="pill"
                  size="md"
                  variant="outline"
                >
                  Asset Store
                </SocialButton>
                <SocialButton
                  link="github"
                  onClick={() => {
                    trackEvent('social_button', {
                      content_type: 'button',
                      event_category: 'engagement',
                      event_label: 'Open Source',
                    });
                  }}
                  shape="pill"
                  size="md"
                  variant="outline"
                >
                  Open Source
                </SocialButton>
              </Stack>
            </Col>
          </Row>
        </Container>
      </PageBanner>
      <Container className="mt-xxxl" fluid>
        <Row>
          <Col className="mb-lg" md={6} lg={4}>
            <SearchInput
              aria-label="Search for assets"
              onChange={search.onChange}
              onSearch={onSearch}
              value={search.query}
            />
          </Col>
          {pagination.paginated && (
            <Col
              className="flex flex-row-reverse md:flex-row justify-end mb-lg"
              md={6}
              lg={8}
            >
              <Pagination {...pagination} onPageChange={setPage} />
            </Col>
          )}
        </Row>
        <Row>
          {(search.results || pagination.items).map((asset, index) => (
            <Col className="py-lg" key={asset.id} size={12} md={6} lg={4}>
              <Thumbnail
                animated
                animation={['fade-in', 'slide-in']}
                aria-label={asset.title}
                as={GatsbyLink}
                image={{
                  alt: asset.title,
                  src: asset.image.sharp.original.src,
                  width: asset.image.sharp.original.width,
                  height: asset.image.sharp.original.height,
                }}
                index={index}
                onClick={() => {
                  if (typeof sessionStorage !== 'undefined') {
                    sessionStorage.setItem(
                      'assets-page',
                      pagination.currentPage.toString(),
                    );
                    sessionStorage.setItem('assets-search', search.query);
                  }
                  trackEvent('thumbnail', {
                    content_type: 'image',
                    event_category: 'engagement',
                    event_label: asset.title,
                    item_id: asset.id,
                  });
                }}
                to={`/assets/${asset.id}`}
              />
              <Text bold marginTop="xxxs">
                {asset.title}
              </Text>
              <Text type="eyebrow">{asset.subtitle}</Text>
            </Col>
          ))}
          {search.results && search.results.length === 0 && (
            <Col>
              <Text color="muted" marginTop="md" weight="500">
                No assets were found matching that search.
              </Text>
            </Col>
          )}
        </Row>
      </Container>
    </Page>
  );
}

export const query = graphql`
  query Assets {
    json: allAssetsJson {
      assets: nodes {
        id: jsonId
        title
        subtitle
        searchQuery
        image {
          sharp: childImageSharp {
            original {
              src
              width
              height
            }
          }
        }
      }
    }
  }
`;
